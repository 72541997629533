import React from 'react'
import { Layout, Menu } from 'antd';
import { WHITE, BORDER_GREY, SECONDARY_TEXT_GREY, PRIMARY_PURPLE } from '../../design/colors'

const { Sider } = Layout;

const styles = {
  userTitle: {
    color: PRIMARY_PURPLE,
    fontWeight: 'bold',
    fontSize: 14,
    marginBottom: 30
  },
  headshot: {
    height: 80, 
    borderRadius: 40,
    width: 80,
    marginTop: 10, 
    marginBottom: 10
  },
  logo: {
    width: 75,
    marginTop: 30,
    marginBottom: 30,
  },
  icon: {
    width: 20,
    marginRight: 10
  },
  sidebar: {
    backgroundColor: WHITE,
    borderRight: `1px solid ${BORDER_GREY}`
  },
  sidebarText: {
    color: SECONDARY_TEXT_GREY
  }
}

const ScheduleIcon = <img
  src="/images/icons/schedule_icon.svg"
  alt="Schedule"
  style={styles.icon}
  />

const TimesheetIcon = <img
  src="/images/icons/timesheet_icon.svg"
  alt="Timesheet"
  style={styles.icon}
  />

const StaffIcon = <img
  src="/images/icons/staff_icon.svg"
  alt="Account"
  style={styles.icon}
  />

const ShiftsIcon = <img
  src="/images/icons/shifts_icon.svg"
  alt="Account"
  style={styles.icon}
  />

const AccountIcon = <img
  src="/images/icons/account_icon.svg"
  alt="Account"
  style={styles.icon}
  />

const Navbar = () => (
  <Sider style={styles.sidebar}>
    <img
      src="/images/Rota_logo.svg"
      alt="Rota logo"
      style={styles.logo}
    />
    <br />
    <img
      src="/images/peter.jpeg"
      alt="Peter Arain"
      style={styles.headshot}
    />
    <p style={styles.userTitle}>Peter Arain</p>
    <Menu mode="inline" defaultSelectedKeys={['1']}>
      <Menu.Item style={styles.sidebarText} key="1" icon={ScheduleIcon}>
        Schedule
      </Menu.Item>
      <Menu.Item style={styles.sidebarText} key="2" icon={TimesheetIcon}>
        Timesheet
      </Menu.Item>
      <Menu.Item style={styles.sidebarText} key="3" icon={StaffIcon}>
        Staff
      </Menu.Item>
      <Menu.Item style={styles.sidebarText} key="4" icon={ShiftsIcon}>
        Shifts
      </Menu.Item>
      <Menu.Item style={styles.sidebarText} key="5" icon={AccountIcon}>
        Account
      </Menu.Item>
    </Menu>
  </Sider>
)

export default Navbar