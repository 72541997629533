import React from 'react'
import moment from 'moment'
import { Layout, Input } from 'antd';
import { WHITE, BORDER_GREY, SECONDARY_TEXT_GREY } from '../../design/colors'

const { Header } = Layout;
const { Search } = Input

const styles = {
  date: {
    color: SECONDARY_TEXT_GREY
  },
  header: { 
    backgroundColor: WHITE,
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 25,
    paddingRight: 25,
    borderBottom: `1px solid ${BORDER_GREY}`
  },
  span: {
    display: 'flex',
  },
  search: {
    alignSelf: 'center'
  }
}

const date = moment().format("D MMM YYYY")

const Navbar = () => { 
  const onSearch = value => console.log(value);

  return (
    <Header style={styles.header}>
      <span style={styles.span}>
        <Search style={styles.search} placeholder="Search..." onSearch={onSearch} />
      </span>
      <span>
        <p style={styles.date}>{date}</p>
      </span>
    </Header>
  )
}

export default Navbar