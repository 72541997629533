import React from 'react'
import { Layout, Tabs, Row, Col } from 'antd';
import CalendarTab from './CalendarTab'
import { BORDER_GREY, PRIMARY_PURPLE } from '../../design/colors'

const { Content } = Layout;
const { TabPane } = Tabs;

const styles = {
  container: {
    margin: 25,
  },
  title: {
    color: PRIMARY_PURPLE,
    fontSize: 36,
    marginBottom: 10
  },
  tabBar: {
    color: PRIMARY_PURPLE,
    borderBottom: `1px solid ${BORDER_GREY}`
  }
}

const MainContent = () => {
  const onChangeTab = (key) => { console.log('key: ', key) }

  return (
    <Content>
      <div style={styles.container}>
        <Row>
          <Col>
            <p style={styles.title}>Schedule</p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
          <Tabs tabBarStyle={styles.tabBar} defaultActiveKey="calendar" onChange={onChangeTab}>
            <TabPane tab="Calendar" key="calendar">
              <CalendarTab />
            </TabPane>
            <TabPane tab="Templates" key="templates">
              Design TBC...
            </TabPane>
            <TabPane tab="Dress Code" key="dresscode">
              Design TBC...
            </TabPane>
            <TabPane tab="Venue" key="venue">
              Design TBC...
            </TabPane>
          </Tabs>
          </Col>
        </Row>
      </div>

    </Content>
  )
}

export default MainContent