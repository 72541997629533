import React from 'react'
import { Calendar, Select, Radio, Typography, Button, Space, Row, Col, Card, Input, Checkbox } from 'antd';
import { SHADOW_GREY, PRIMARY_PURPLE, SECONDARY_PURPLE, WHITE } from '../../../design/colors';

const { Search } = Input

const CalendarTab = () => {
  const styles = {
    buttons: {
      marginTop: 10,
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    button: {
      color: PRIMARY_PURPLE,
      borderRadius: 10,
      border: `1px solid ${SECONDARY_PURPLE}`
    },
    primaryButton: {
      backgroundColor: PRIMARY_PURPLE,
      color: WHITE,
      borderRadius: 10,
      border: `1px solid ${SECONDARY_PURPLE}`
    },
    smallCalendarCard: {
      boxShadow: `1px 1px 1px 1px ${SHADOW_GREY}`,
      height: 400,
      width: '100%',
      alignSelf: 'center',
      display: 'flex',
      borderRadius: 10,
    },
    largeCalendarCard: {
      boxShadow: `1px 1px 1px 1px ${SHADOW_GREY}`,
      height: 620,
      width: '100%',
      alignSelf: 'center',
      display: 'flex',
      borderRadius: 10,
    },
    categoriesCard: {
      boxShadow: `1px 1px 1px 1px ${SHADOW_GREY}`,
      height: 200,
      width: '100%',
      display: 'flex',
      borderRadius: 10,
    },
    row: {
      marginBottom: 20,
      width: '100%'
    },
    search: {
      display: 'flex',
    }
  }

  const onChangeCheckbox = () => {
    console.log('clicked')
  }

  const onPanelChange = (value, mode) => {
    console.log(value, mode);
  }

  return (
    <>
      <Row style={styles.row}>
        <Col style={styles.buttons} span={24}>
          <Space>
            <Button style={styles.primaryButton}>Add Shift +</Button>
            <Button style={styles.button}>Worker Type</Button>
            <Button style={styles.button}>Worker Status</Button>
          </Space>
        </Col>
      </Row>
      <Row style={styles.row}>
        <Col span={8}>
          <Row style={styles.row}>
            <Card style={styles.smallCalendarCard}>
            <div className="site-calendar-customize-header-wrapper">
              <Calendar
                fullscreen={false}
                headerRender={({ value, type, onChange, onTypeChange }) => {
                  const start = 0;
                  const end = 12;
                  const monthOptions = [];

                  const current = value.clone();
                  const localeData = value.localeData();
                  const months = [];
                  for (let i = 0; i < 12; i++) {
                    current.month(i);
                    months.push(localeData.monthsShort(current));
                  }

                  for (let index = start; index < end; index++) {
                    monthOptions.push(
                      <Select.Option className="month-item" key={`${index}`}>
                        {months[index]}
                      </Select.Option>,
                    );
                  }
                  const month = value.month();

                  const year = value.year();
                  const options = [];
                  for (let i = year - 10; i < year + 10; i += 1) {
                    options.push(
                      <Select.Option key={i} value={i} className="year-item">
                        {i}
                      </Select.Option>,
                    );
                  }
                  return (
                    <div style={{ padding: 8 }}>
                      <Row gutter={8}>
                        <Col>
                          <Radio.Group size="small" onChange={e => onTypeChange(e.target.value)} value={type}>
                            <Radio.Button value="month">Month</Radio.Button>
                            <Radio.Button value="year">Year</Radio.Button>
                          </Radio.Group>
                        </Col>
                        <Col>
                          <Select
                            size="small"
                            dropdownMatchSelectWidth={false}
                            className="my-year-select"
                            onChange={newYear => {
                              const now = value.clone().year(newYear);
                              onChange(now);
                            }}
                            value={String(year)}
                          >
                            {options}
                          </Select>
                        </Col>
                        <Col>
                          <Select
                            size="small"
                            dropdownMatchSelectWidth={false}
                            value={String(month)}
                            onChange={selectedMonth => {
                              const newValue = value.clone();
                              newValue.month(parseInt(selectedMonth, 10));
                              onChange(newValue);
                            }}
                          >
                            {monthOptions}
                          </Select>
                        </Col>
                      </Row>
                    </div>
                  );
                }}
                onPanelChange={onPanelChange}
              />
            </div>
            </Card>
          </Row>
          <Row style={styles.row}>
            <Card style={styles.categoriesCard}>
              <Row>
                <Search style={styles.search} placeholder="Search..." />
              </Row>
              <Row style={{ marginTop: 10, marginBottom: 5, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Space>
                <Checkbox style={{ color: 'red', display: 'flex' }} onChange={onChangeCheckbox} />
                Select All
                </Space>
              </Row>
              <Row style={{ marginTop: 5, marginBottom: 5, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Space>
                <Checkbox style={{ display: 'flex' }} onChange={onChangeCheckbox} />
                Category 1
                </Space>
              </Row>
              <Row style={{ marginTop: 5, marginBottom: 5, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Space>
                <Checkbox style={{ display: 'flex' }} onChange={onChangeCheckbox} />
                Category 2
                </Space>
              </Row>
              <Row style={{ marginTop: 5, marginBottom: 5, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Space>
                <Checkbox style={{ display: 'flex' }} onChange={onChangeCheckbox} />
                Category 3
                </Space>
              </Row>
            </Card>
          </Row>
        </Col>
        <Col span={16} style={{ paddingLeft: 15 }}>
          <Card style={styles.largeCalendarCard}>
            Large calender here
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default CalendarTab