export const PINK = 'pink'
export const GREY = 'grey'

export const WHITE = '#FFFFFF'
export const BACKGROUND_BLUE = '#F8F8FF'
export const PRIMARY_PURPLE = '#6283FA'
export const SECONDARY_PURPLE = '#B5C2F3'
export const BORDER_GREY = '#CAD4F8'
export const PRIMARY_TEXT_BLACK = '#101C47'
export const SECONDARY_TEXT_GREY = '#797598'

export const SHADOW_GREY = '#E8E8E8'