import React from 'react';
import { Layout } from 'antd';
import { BACKGROUND_BLUE } from './design/colors'
import './App.css';

import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import MainContent from './components/MainContent'

const styles = {
  layout: { 
    minHeight: '100vh',
    backgroundColor: BACKGROUND_BLUE
  }
}

function App() {
  return (
    <div className="App">
      <Layout style={styles.layout}>
        <Sidebar />
        <Layout>
          <Navbar />
          <MainContent />
        </Layout>
      </Layout>
    </div>
  );
}

export default App;
